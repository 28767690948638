import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { GeneralService } from '../../Services/generalservice.service'
@Component({
  selector: 'app-packagedetails',
  templateUrl: './packagedetails.component.html',
  styleUrls: ['./packagedetails.component.scss']
})
export class PackagedetailsComponent implements OnInit {
    loginDet: any;
    PackageDetals: any;
    GSTprice: number;
    PackPricewithGST: any;
    TaxnId: any;
    responsestatus: any;
    arr: any = [];
    n: any;
    PricewithGST: any;
    logindata: string;
    TransStatus: any;

    constructor(public route: ActivatedRoute, public https: HttpClient, public router: Router,
        public generalService: GeneralService,    ) {
        debugger
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        debugger
        this.PackageDetals = JSON.parse(localStorage.getItem("Package"));
        //this.PackageDetals = JSON.parse(this.route.snapshot.paramMap.get("Package"));
        var GST = 15;
        this.PackPricewithGST = ((this.PackageDetals.packagePrice * GST) / 100) + this.PackageDetals.packagePrice;
        this.GSTprice = ((this.PackageDetals.packagePrice * GST) / 100);
        //this.Gettransactionstatus();
        var PaymentStatus = localStorage.getItem("PaymentStatus");
        if (PaymentStatus == null) {
            this.Gettransactionstatus();
        } else {
            localStorage.removeItem("PaymentStatus");
            localStorage.removeItem("TransId");
        }



    }

  ngOnInit(): void {
  }


    Paywithphonepe() {
        debugger
        if (this.loginDet != null) {            
            var d = new Date(),
                n = d.getTime();
            this.TaxnId = n;
            var Amount1 = (parseFloat(this.PackPricewithGST) * 100);
            var Amount = Number(Amount1.toFixed(2));
            // const apiUrl = `http://localhost:54429/api/LawyerChoices/PhonepeMakePayment`;
            const apiUrl = "https://lawyerschoice.in/webservices/api/LawyerChoices/PhonepeMakePayment"; // Replace with your actual API URL
            const httpHeaders = new HttpHeaders({
                'Content-Type': 'application/json',
                'X-VERIFY': 'df7191d8bd4018e6b2d00fc4b1b5dbe912efaa24a97e951d47f49e81f4a13930###1',
            }); // Replace with your headers
            const dynamicCallbackUrl = 'https://lawyerschoice.in/PackageDetails';
            // Construct the payment request
            var paymentRequest = {
                merchantId: 'LAWYERSCHOICEONLINE',
                merchantTransactionId: this.TaxnId,
                merchantUserId: 'LawyersChoice123',
                amount: Amount,
                redirectMode: 'REDIRECT',
                redirectUrl: dynamicCallbackUrl,
                callbackUrl: dynamicCallbackUrl,
                mobileNumber: this.loginDet.Mobile,
                paymentInstrument: {
                    type: "PAY_PAGE"
                }
            };
            debugger
            this.https.post(apiUrl, paymentRequest, { headers: httpHeaders }).subscribe(
                (response: any) => {
                    debugger
                    let redirectUrl = response.data.instrumentResponse.redirectInfo.url;
                    window.open(redirectUrl, '_blank');
                    //this.PlaceOrder();
                    console.log('API response:', response);
                    localStorage.setItem("TransId", this.TaxnId);
                    this.Gettransactionstatus();
                }, (error: any) => {
                    console.error('API error:', error);
                    //handle the error here
                }
            )
        } else {
            this.generalService.ShowAlert("ALERT", "Please login, To Subscribe a Package", 'error');
            this.router.navigate(['/login']);
        }
    }

    Gettransactionstatus() {
        debugger
        var TransId = localStorage.getItem("TransId")
        //var Tid = "1713788208719"
        if (TransId != null) {
  
            var Url = `https://lawyerschoice.in/webservices/api/LawyerChoices/CheckPaymentStatus?merchantTransactionId=${TransId}`;
        //var Url = `http://localhost:54753/api/BuddyOnWheel/CheckPaymentStatus?merchantTransactionId=${this.TaxnId}`;
      
        this.https.get(Url).subscribe(
            (response: any) => {

                console.log('Status response:', response.data.state);
                debugger
                this.responsestatus = response.data.state

                if (this.responsestatus == "COMPLETED" || this.responsestatus == "COMPLETED ") {
                    localStorage.setItem("PaymentStatus", this.responsestatus);
                    this.PlaceOrder();

                }
            },
            )
        }
    }

    PlaceOrder() {
        var TransId = localStorage.getItem("TransId")
        debugger
        this.arr = [];
        this.n = ""
        var d = new Date();
        this.n = d.getTime();       
        this.arr.push({
            UserID: this.loginDet.CustomerID,
            TokenID: this.loginDet.TokenID,
            SubscribtionNo: this.PackageDetals.PackageID,            
            TransactionID: TransId,
            Price: this.PackPricewithGST,
            TransactionStatus: 'SUCCESS',
            PackageID: this.PackageDetals.PackageID
        });
        let formData: FormData = new FormData();
        formData.append('Param', JSON.stringify(this.arr));
        formData.append('Flag', '1');
        var url = "api/LawyerChoices/SP_Payment_CRUD"
        // this.http.post(url, formData).pipe(map(res => res)).subscribe((data: any) => {
        this.generalService.PostData(url, formData).then((data: any) => {
            debugger
            if (data == "SUCCESS") {
                this.Login();
                this.generalService.ShowAlert("Success", "Your Subscription added successfully", "Success");
                this.router.navigate(['/Judges']);
            }
        })
    }

    Login() {
        var arr = [{
            Mobile: this.loginDet.Mobile,
            Password: this.loginDet.Password,
        }]
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(arr));
        var url = "api/LawyerChoices/Login";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            if (data != "Invalid User") {
                this.logindata = JSON.stringify(data[0]);
                localStorage.setItem("LoginDetails", this.logindata);
            }
        })
    }

    back12() {
        this.router.navigate(['/Subscribe']);
    }

}
