<div class="container-fluid banner_image breadcrumb__overlay">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="breadcrumb_heading">
                    <div class="banner_heading">Profile</div>
                    <nav aria-label="breadcrumb crumb">
                        <ol class="breadcrumb">
                            <li class="Home"><a routerLink="/">Home >></a></li>
                            <li style="color:white;"><a>Profile</a></li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>
<br />
<div class="container">
    <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-6">

            <img src="../../../assets/lawyersimages/profilee.jpeg" />
        </div>
        <div class="col-lg-6 col-md-4 col-lg-3 mt-4">
            <div class="card centered">
                <!--<img class="card-img-top" src="https://picsum.photos/200/150/?random">-->
                <div class="card-block">
                    <!--<figure class="profile">
                        <img src="../../../assets/lawyersimages/lawyer.png" class="profile-avatar" alt="" />
                    </figure>-->
                    <h4 class="card-title mt-3 fontt">{{Customer_Name}}</h4>
                    <div class="row">
                        <div class="col-lg-3 ">
                            <div class="card-block">
                                <figure class="profile">
                                    <img src="../../../assets/lawyersimages/lawyer.png" class="profile-avatar" alt="" />
                                </figure>
                            </div>
                        </div>
                        <div class="col-lg-4 col-xs-6">
                            <div class="left_details">
                                <div>Mobile:</div>
                                <div>Email ID:</div>
                                <div>package Price:</div>
                                <div>Expiry Date:</div>
                                <div>Remaining Days:</div>
                                <div>Package Discription:</div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-xs-6">
                            <div class="right_details">
                            <div><b>{{Mobile_No}}</b></div>
                            <div><b>{{Email_ID}}</b></div>
                            <div><b>{{package_Price}}</b></div>
                            <div><b>{{Expiry_Date}}</b></div>
                            <div><b>{{Remaining_Days}}</b></div>
                            <div><b>{{Package_Discription}}</b></div>
                            </div>
                        </div>
                    </div>          

                </div>
        
            </div>

        </div>
        <div class="col-lg-6">
        </div>
    </div>
</div>
