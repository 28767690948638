<div class="container-fluid banner_image breadcrumb__overlay">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="breadcrumb_heading">
                    <div class="banner_heading">Registration</div>
                    <nav aria-label="breadcrumb crumb">
                        <ol class="breadcrumb">
                            <li class="Home"><a routerLink="/">Home >></a></li>
                            <li style="color:white;"><a>Registration</a></li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="register">
        <div class="row">
            <div class="col-md-3 register-left">
                <img src="https://image.ibb.co/n7oTvU/logo_white.png" alt="" />
                <h3 class="fontt">Welcome</h3>
                <p class="transition">Lawyers Choice</p>
               
                <!--<input type="submit" name="" value="Login" routerLink="/login" class="reg_1" /><br />-->
            </div>
            <div class="col-md-9 register-right">
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <h3 class="register-heading fontt">Registration</h3>
                        <div class="row register-form">
                            <form [formGroup]="form" (ngSubmit)="CheckMobile(this.form.value.mobile)">
                                <div class="col-md-6">

                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <label>Full Name</label>
                                            </div>
                                            <div class="col-lg-8">
                                                <input formControlName="name" class="form-control validation-field"
                                                       placeholder="Enter Full Name" type="text">
                                                <small class="text-danger" *ngIf="form.get('name').touched">
                                                    <span *ngIf="form.get('name').hasError('required')">Full Name is required</span>
                                                    <span *ngIf="form.get('name').hasError('minlength')">Minimum of 3 characters</span>
                                                </small>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <label>Mobile</label>
                                            </div>
                                            <div class="col-lg-8">
                                                <input formControlName="mobile" class="form-control validation-field"
                                                       placeholder="Enter Mobile number" type="text" maxlength="10">
                                                <small class="text-danger" *ngIf="form.get('name').touched">
                                                    <span *ngIf="form.get('mobile').hasError('required')">mobile number is required</span>
                                                    <span *ngIf="form.get('mobile').hasError('minlength')">please enter 10 digits mobile number</span>
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <label>Email</label>
                                            </div>
                                            <div class="col-lg-8">
                                                <input formControlName="email" class="form-control validation-field" placeholder="Enter EmailID" type="text">
                                                <small class="text-danger" *ngIf="form.get('email').touched">
                                                    <span *ngIf="form.get('email').hasError('required')">Email is required</span>
                                                    <span *ngIf="form.get('email').hasError('invalidEmail')">Invalid email address</span>
                                                </small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <label>Password</label>
                                            </div>
                                            <div class="col-lg-8">
                                                <div class="input-field">
                                                    <input type="{{ passwordshow ? 'text' : 'password' }}" formControlName="password" class="form-control validation-field"
                                                           placeholder="Enter 6 Digits Password" minlength="6">
                                                    <span class="toggle-password" (click)="togglePasswordVisibility()">
                                                        <i class="fa" [ngClass]="{ 'fa-eye': passwordshow, 'fa-eye-slash': !passwordshow }"></i>
                                                    </span>
                                                </div> <small class="text-danger" *ngIf="form.get('password').touched">
                                                    <span *ngIf="form.get('password').hasError('required')">Password is required</span>
                                                    <span *ngIf="form.get('password').hasError('minlength')">Password isn't long enough, minimum of 6 characters</span>
                                                </small>
                                            </div>


                                        </div>
                                    </div>

                                </div>
                                <div style="margin-left:14px;">
                                    <input type="checkbox" class="checkbox" style="width: 20px; margin-top: -7%;" formControlName="Disclaimer">
                                    I agree to the <a href="termsandconditions.html" target="_blank">Terms Conditions</a> and <a href="privacypolicy.html" target="_blank">Privacy Policy</a>
                                </div>
                                <div class="col-md-6">
                                    <img src="../../../assets/lawyersimages/logo-removebg-preview.png" class="logo" style="margin-left: 426px; margin-top: -365px;" />
                                    <button class="btnRegister" (click)="CheckMobile(this.form.value.mobile)" type="submit" value="Register">
                                        Sign Up
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

           

            <!-- #region check otp popup for forgot password-->

            <div class="modal" id="myModal3">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header state modal-primary">
                            <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Enter Otp</h4>
                            <button type="button" class="close" data-dismiss="modal13" aria-label="Close"><span aria-hidden="true">X</span></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <label>Please Check your OTP<span style="color:red">*</span></label>
                                <input [(ngModel)]="OTP" class="form-control Caps" placeholder="Enter Otp" type="text" style="width:100%">
                            </div>
                            <div class="row" style="float:right;cursor:pointer;margin-right:10px;margin-top:1%">
                                <a class="resend" (click)="SendOtpForMobile(form.value)">Resend OTP</a>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary" (click)="verifyotp1(OTP,form.value)">Submit</button>
                            <button id="closemodal" type="button" class="btn btn-default" data-dismiss="modal13">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- #endregion -->

        </div>
    </div>
</div>
<style>
    .form-control{
        width:150%
    }
    @media only screen and (max-width:660px){
        .form-control {
            width: 100%
        }
    }

</style>

