<div class="container-fluid banner_image breadcrumb__overlay">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="breadcrumb_heading">
                    <div class="banner_heading">Judgements</div>
                    <nav aria-label="breadcrumb crumb">
                        <ol class="breadcrumb">
                            <li class="Home"><a routerLink="/">Home >></a></li>
                            <li style="color:white;"><a>Judgements</a></li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="container-fluid">
    <div class="row">

        <div class="col-lg-2 form_1">
            <input type="text" [(ngModel)]="searchQuery" placeholder="Search Judgement..." class="form " />
        </div>
            <div class="col-lg-1">
                <img src="../../../assets/lawyersimages/adserch.png" class="advance" (click)="showmodal()" title="Advance Search" />
            </div>

       
   
        <div class="col-lg-9">
            <div class="row">
                <div class="col-lg-2" (click)="Getjudgecourtyear(1)">
                    <div class="cardn" [ngClass]="{'BackGroung':selected1==1}" (click)='changeTagColor(1)'>
                        <div class="row">
                            <div class="col-lg-2">
                                <img src="../../../assets/lawyersimages/Copy of Icon.webp" class="badges" />
                            </div>
                            <div class="col-lg-10">
                                <div class="card_space" (click)="GetJudgements(1)">&nbsp;<b>SC Court</b>&nbsp;&nbsp;<b>{{TotalSCJudgements}}</b></div>
                            </div>                           
                        </div>

                    </div>
                </div>

                <div class="col-lg-2" (click)="Getjudgecourtyear(2)">
                    <div class="cardn " [ngClass]="{'BackGroung':selected1==2}" (click)='changeTagColor(2)'>

                        <div class="row ">
                            <div class="col-lg-2">
                                <img src="../../../assets/lawyersimages/Copy of Icon.webp" class="badges" />
                            </div>
                            <div class="col-lg-10">
                                <div class="card_space" (click)="GetJudgements(2)">&nbsp;<strong>AP Court</strong>&nbsp;&nbsp;<b>{{TotalAPJudgements}}</b></div>
                            </div>
                          
                        </div>
                    </div>
                </div>

                <div class="col-lg-2" (click)="Getjudgecourtyear(3)">
                    <div class="cardn" [ngClass]="{'BackGroung':selected1==3}" (click)='changeTagColor(3)'>
                        <div class="row">
                            <div class="col-lg-2">
                                <img src="../../../assets/lawyersimages/Copy of Icon.webp" class="badges" />
                            </div>
                            <div class="col-lg-10">
                                <div class="card_space" (click)="GetJudgements(3)">&nbsp;<b>TS Court</b>&nbsp;&nbsp;<b>{{TotalTSJudgements}}</b></div>
                            </div>
                          
                        </div>
                    </div>
                </div>

                <div class="col-lg-2" (click)="Getjudgecourtyear(4)">
                    <div class="cardn" [ngClass]="{'BackGroung':selected1==4}" (click)='changeTagColor(4)'>
                        <div class="row">
                            <div class="col-lg-2">
                                <img src="../../../assets/lawyersimages/Copy of Icon.webp" class="badges" />
                            </div>
                            <div class="col-lg-10">
                                <div class="card_space" (click)="GetJudgements(4)">&nbsp;<b>Civil Court</b>&nbsp;&nbsp;<b>{{TotalCivilJudgements}}</b></div>
                            </div>
                          
                        </div>
                    </div>
                </div>
                <div class="col-lg-2" (click)="Getjudgecourtyear(5)">
                    <div class="cardn " [ngClass]="{'BackGroung':selected1==5}" (click)='changeTagColor(5)'>

                        <div class="row ">
                            <div class="col-lg-2">
                                <img src="../../../assets/lawyersimages/Copy of Icon.webp" class="badges" />
                            </div>
                            <div class="col-lg-10">
                                <div class="card_space" (click)="GetJudgements(5)">&nbsp;<b>Criminal </b>&nbsp;&nbsp;<b>{{TotalCriminalJudgements}}</b></div>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div class="col-lg-2" (click)="Getjudgecourtyear(6)">
                    <div class="cardn " [ngClass]="{'BackGroung':selected1==6}" (click)='changeTagColor(6)'>

                        <div class="row ">
                            <div class="col-lg-1">
                                <img src="../../../assets/lawyersimages/Copy of Icon.webp" class="badges" />
                            </div>
                            <div class="col-lg-10">
                                <div class="card_space" (click)="GetJudgements(6)">&nbsp;<b>ACR Court</b>&nbsp;&nbsp;<b>{{TotalACRJudgements}}</b></div>
                            </div>
                           
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="row">
        <!--<div class="col-lg-3">
            <div class="ads">
                <span (click)="showmodal()" style=" cursor: pointer;">Advance&nbsp;Search</span>
                <img src="../../../assets/lawyersimages/adserch.png" class="advance" (click)="showmodal()" title="Advance Search" />
            </div>
        </div>-->
        <div class="col-lg-12">
            <button class="year" (click)="calender(item.Years)" *ngFor="let item of YerasData"
                    [ngClass]="{ 'selected': item.Years === selectedYear }">
                {{item.Years}}
            </button>&nbsp;&nbsp;
            <button class="year" (click)="clearrrr()">Clear</button>&nbsp;
            <div *ngIf="manu==1" class="months">
                <button class="year1" (click)="month(01)" [ngClass]="{ 'selected': selectedmonth === 01 }">jan</button>&nbsp;
                <button class="year1" (click)="month(02)" [ngClass]="{ 'selected': selectedmonth === 02 }">Feb</button>&nbsp;
                <button class="year1" (click)="month(03)" [ngClass]="{ 'selected': selectedmonth === 03 }">March</button>&nbsp;
                <button class="year1" (click)="month(04)" [ngClass]="{ 'selected': selectedmonth === 04 }">Apirl</button>&nbsp;
                <button class="year1" (click)="month(05)" [ngClass]="{ 'selected': selectedmonth === 05 }">May</button>&nbsp;
                <button class="year1" (click)="month(06)" [ngClass]="{ 'selected': selectedmonth === 06 }">june</button>&nbsp;
                <button class="year1" (click)="month(07)" [ngClass]="{ 'selected': selectedmonth === 07 }">july</button>&nbsp;
                <button class="year1" (click)="month(08)" [ngClass]="{ 'selected': selectedmonth === 08 }">Aug</button>&nbsp;
                <button class="year1" (click)="month(09)" [ngClass]="{ 'selected': selectedmonth === 09 }">Sept</button>&nbsp;
                <button class="year1" (click)="month(10)" [ngClass]="{ 'selected': selectedmonth === 10 }">Oct</button>&nbsp;
                <button class="year1" (click)="month(11)" [ngClass]="{ 'selected': selectedmonth === 11 }">Nov</button>&nbsp;
                <button class="year1" (click)="month(12)" [ngClass]="{ 'selected': selectedmonth === 12 }">Dec</button>&nbsp;
            </div>
        </div>
        <div class="col-lg-3">
            <div class="cards card_mobile scrollbar" id="style-1">
                <div *ngFor="let person of judgementcourtyear" class="force-overflow" (click)="Getpdf1(person.JudgementID)">
                    <div class="card middle-pane" *ngIf="matchSearchQuery(person)">
                        <ng-container *ngIf="!LoginDetails?.PackageID" (click)="showSubscribePopup()">
                            <div class="card_i">
                                <div class="row ">
                                    <div class="col-lg-6 col-xs-6">
                                    </div>

                                    <div class="col-lg-6 col-xs-6">
                                        <div style="float: right;">{{person.JudgementDate |date:'dd-MM-yyyy'}}</div>
                                    </div>
                                    <div class="col-lg-1 col-xs-6">
                                        <img src="../../../assets/lawyersimages/textlogo.png" class="pdg" />
                                    </div>
                                    <div class="col-lg-11 col-xs-6">
                                        <div>{{person.JudgementTitle}}</div>
                                    </div>

                                </div>
                            </div>

                        </ng-container>
                        <div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-9">
            <br />
            <div class="">
                <div class="row">
                    <div class="col-lg-12">

                        <!--<div class="" *ngIf="manohar" style="height:100%;border: 1px solid #fb8d8d;"  oncontextmenu="return false;">
        <iframe [src]="PDFPath" type="application/pdf" width="100%" height="500px">
            PDF cannot be displayed
        </iframe>

    </div>-->

                        <div class="" *ngIf="manohar" style="height:100%;border: 1px solid #fb8d8d;" oncontextmenu="return false;">
                            <style>
                                /* Disable text selection and copying */
                                .no-select {
                                    -webkit-touch-callout: none; /* iOS Safari */
                                    -webkit-user-select: none; /* Safari */
                                    -khtml-user-select: none; /* Konqueror HTML */
                                    -moz-user-select: none; /* Old versions of Firefox */
                                    -ms-user-select: none; /* Internet Explorer/Edge */
                                    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera, and Firefox */
                                    pointer-events: none; /* Disable pointer events to prevent text selection */
                                }
                            </style>
                            <iframe [src]="PDFPath" type="application/pdf" width="100%" height="500px" class="no-select">
                                PDF cannot be displayed
                            </iframe>
                        </div>
                    </div>
                    <div class="col-lg-10">

                    </div>
                    <div>
                        <h2 class="heading"><span>&nbsp;</span></h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal" style=" top: 15px;left: 2%;">
    <div class="modal-dialog" role="document" style="top: -3%;">
        <div class="modal-content" style="width:125%">
            <div class="modal-header state modal-primary" style="background-color: #ef2700;    padding: 10px;">
                <h4 style="text-align:left;font-family: 'Roboto Condensed'; font-style: normal;color:white" class="modal-title" id="modal-primary-label">Advanced Search For judgements</h4>
                <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" style="color:white"> X </span></button>
            </div>
            <div class="modal-body">
                <div class="row">
                   
                        <div class="col-lg-4" >
                            <div class="labelname">Judgement Title</div>
                            <div class="labelname">Bench Name</div>
                            <div class="labelname">DOC Type</div>
                            <div class="labelname">Citation No</div>
                            <div class="labelname">Head Note</div>
                            <div class="labelname">KeyWord</div>
                            <div class="labelname">Judgement Date</div>
                        </div>
                        <div class="col-lg-6">
                            <input type="text" placeholder="Search Judgement Title" class="inputbox bord" [(ngModel)]="JudgementTitle" /><br />
                            <input type="text" placeholder=" Search Bench Name" class="inputbox bord" [(ngModel)]="Bench" /><br />
                            <input type="text" placeholder=" Search DOC Type" class="inputbox bord" [(ngModel)]="DOC_Name" />
                            <input type="text" placeholder=" Search Citation No" class="inputbox bord" [(ngModel)]="CitationNo" />
                            <input type="text" placeholder=" Search Head Note" class="inputbox bord" [(ngModel)]="LawyerName" />
                            <input type="text" placeholder=" Search KeyWord" class="inputbox bord" [(ngModel)]="KeyWord" />
                            <input matInput type="date" name="TentativeStartdate" placeholder="JudgmentDate" style="width: 245px;" class="inputbox datepicker bord" [(ngModel)]="JudgementDate" />
                        </div>
               

                    <!--<div class="col-lg-4"></div>-->

                </div>
                <br />
                <button class="buuton2" role="button" (click)="search()"> Apply</button> &nbsp;
                <button class="buuton2" role="button" (click)="clear()"> Clear</button>


            </div>
        </div>
    </div>
</div>
<script>
    function myFunction() {
        var input, filter, ul, li, a, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();
        ul = document.getElementById("myUL");
        li = ul.getElementsByTagName("li");
        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("a")[0];
            txtValue = a.textContent || a.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
            } else {
                li[i].style.display = "none";
            }
        }
    }
</script>

<script type="text/javascript">
    function googleTranslateElementInit() {
        new google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
    }
</script>
<script type="text/javascript" src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script>


<style>
    .goog-logo-link {
        display: none !important;
    }

    .goog-te-gadget {
        color: transparent !important;
    }

        .goog-te-gadget .goog-te-combo {
            color: #7a7a7e !important;
        }

    @media only screen and (min-width:884) {
        .sticky li {
            border: 1px solid rgb(151 35 139);
            list-style-type: none;
            background-color: #fff;
            color: #efefef;
            height: 45px;
            padding: 0;
            margin: 0 82px 8px;
            transition: all 0.25s ease-in-out;
            cursor: pointer;
            border-radius: 23px;
        }

            .sticky li:hover {
                margin-left: -10%;
            }

            .sticky li img {
                float: left;
                margin: 5px 5px 5px 4px;
            }
    }

</style>
