<!--<p>packagedetails works!</p>-->
<br />
<div class="container-fluid banner_image breadcrumb__overlay  breddd">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="breadcrumb_heading">
                    <div class="banner_heading">Package Details</div>
                    <nav aria-label="breadcrumb crumb">
                        <ol class="breadcrumb">
                            <li class="Home"><a routerLink="/">Home >></a></li>
                            <li style="color:white;"><a>&nbsp; Package Details</a></li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>
<br />

<div class="container">
    <div class="row">
        <div class="col-lg-6">
            <div class="card1">
                <div class="card1-image">
                    <img src="../../../assets/lawyersimages/package (1).jpg" />
                </div>
                
                
            </div>
        </div>
        <div class="col-lg-6">
            <div class="card">
                <div class="row content">
                    <div class="col-lg-12">
                        <div>
                            <h1 class="fontt"><b>Lawyers Choice</b></h1>
                        </div>
                    </div>
                    <hr />
                    <div class="col-lg-6 col-md-6">
                        <b>Package</b>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        
                    </div>
                    <div class="col-lg-6 col-md-6">
                        Summary 
                    </div>
                    <div class="col-lg-6 col-md-6" style="text-align:right;padding-right:5%">
                        Billing
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div>Package Duration</div>                            
                            <!--<h4 class="fontt">Lawyers Choice</h4>-->
                            <h5 class="fontt">Package Description</h5>
                            {{PackageDetals.PackageDiscription}}
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" style="background-image: url('../../../assets/lawyersimages/bg1.png');">
                            <div class="amount nt subs">{{PackageDetals.PackageName}}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <h5 class="fontt">Package Price</h5>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="amount subs">&#X20B9;{{PackageDetals.packagePrice}}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <h5 style="font-family: poppins; font-style:normal;color: black;">
                                GST : 15%
                            </h5>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="amount subs">&#X20B9;{{GSTprice}}</div>
                        </div>
                        <hr />
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <h5 style="color: black; font-family: poppins;font-style:normal;">Total</h5>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="amount subs">&#X20B9;{{PackPricewithGST}}</div>
                        </div>
                    
                    </div>
                    <div class="row">
                        <div class="col-2 text-center">
                            <button class="Payback" (click)="back12()">
                                Back
                            </button>
                        </div>
                        <div class="col-10 text-center">

                            <button class="button-82-pushable" role="button" (click)="Paywithphonepe()">
                                <span class="button-82-shadow"> </span>
                                <span class="button-82-edge"> </span>
                                <span class="button-82-front text">
                                    Proceed to Pay
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
