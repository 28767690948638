import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { FormGroup, FormsModule, FormControl  } from '@angular/forms'
import { ReactiveFormsModule } from '@angular/forms';
import { NgxCaptureService } from 'ngx-capture';
import { HttpClient } from '@angular/common/http';
import { GeneralService } from '../../../../app/Services/generalservice.service';

import Swal from 'sweetalert2';
@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    EnquiryAry: any = [];
    formGroup: any;
    admin: any;
    TemplateId: any;
    MessageText: any;
    //ApiURL: any;
    contactForm: FormsModule;
    spinner: boolean = false;
    imgBase64: any = '';
    message: any;
    name: any;
    email: any;
    subject: any;
    number: any;

    HomeUrl: any;
    loginDet: any;
    constructor(private captureService: NgxCaptureService, private viewportScroller: ViewportScroller, public generalService: GeneralService,
        private http: HttpClient, public fb: FormsModule, public Rm: ReactiveFormsModule) {

        debugger
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);

    }

    ngOnInit() {
       // this.contactForm.controls['name'].setValue(this.loginDet.CustomerName)
    }

    @ViewChild('screen', { static: true }) screen: any;

    capture() {
        debugger

        this.captureService.getImage(this.screen.nativeElement, true).subscribe(img => {
            console.log(img);
            this.imgBase64 = img
            this.save()
        })
    }

    DataURIToBlob(dataURI: string) {
        debugger
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
    }
    ip = "http://localhost:62161/Images"

    save() {
        debugger
        const file = this.DataURIToBlob(this.imgBase64)
        const formData = new FormData();
        formData.append('file', file, 'image.png')
        let url = "upload2.php"
        this.http.post(this.ip + url, formData).subscribe(data => {
            // this.generalService.PostData(url, UploadFile).then((data: any) => {


        })
    }

    onSubmit(form: any) {
        debugger;

        this.EnquiryAry = [];
        this.EnquiryAry.push({
            CustomerName: form.name,
            EmailID: form.email,
            Subject: form.subject,
            Mobile: form.number,
            Message: form.message


        })
        var UploadFile = new FormData();
        UploadFile.append("Email", JSON.stringify(this.EnquiryAry));
        var url = "api/LawyerChoices/EnquiryMailToAdmin"
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.clear();
            debugger;

            //this.contactForm.reset();
            Swal.fire(
                'SUCCESS',
                'Your Enquiry has been submitted successfully. Our Team will contact you soon.',
                'success'

            )
            // this.sendsms();
        });

    }


    submit(form) {
        var name = form.name;
        console.log(name);     

        var email = form.email;
        console.log(email);

        var subject = form.subject;
        console.log(subject);

        var number = form.number;
        console.log(number);

        var message = form.message;
        console.log(message);
    }

    clear() {
        debugger
        this.name = '';
        this.email = '';
        this.subject = '';
        this.number = '';
        this.message = '';    

    }
    

    sendsms() {
        debugger
        this.admin = 9110395584;
        this.TemplateId = 1207164812642544439;
        this.MessageText = "Thank you for your enquiry, we will contact you soon Company mobile number " + this.admin + " Ykmart ";
        var url = 'api/Products/raisenquiry_sendsms_customer';

        let formdata = new FormData();
        formdata.append("admin", this.admin)
        formdata.append("Mobile", this.EnquiryAry[0].Mobile)
        formdata.append("SMS", this.MessageText)
        formdata.append("TemplateId", this.TemplateId)
        this.generalService.PostData(url, formdata).then((data: any) => {
        })
    }

    ngAfterViewInit() {
        document.getElementById('preloader').classList.add('hide');
    }
}
