import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    loginDet: any;
    C_ID: any;

    constructor(private viewportScroller: ViewportScroller, public router: Router, ) {
        debugger
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        this.C_ID = this.loginDet
    }
  public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
    }


    onClick1() {
        this.router.navigateByUrl('/Coreservices')

    }
}
