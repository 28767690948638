import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { GeneralService } from '../../Services/generalservice.service';
import { FormGroup, FormBuilder, FormsModule, Validators } from '@angular/forms'
import { MessageService } from 'primeng/api';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    HomeUrl: any;
    loginDet: any;
    dataResult: any;
    arr: any[];
    Customer_Name: any;
    Mobile_No: any;
    Email_ID: any;
    Package_Discription: any;
    package_Price: any;
    Remaining_Days: any;
    Expiry_Date: any;

    constructor(private viewportScroller: ViewportScroller, public generalService: GeneralService,
        public http: HttpClient, ) {

        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;

            debugger

        });

        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));

    }



    ngOnInit(): void {
        debugger
        this.GetProfile();

    }

    GetProfile() {
        debugger
        var param = this.loginDet.CustomerID;
        var UploadFile = new FormData();
        UploadFile.append("Param1", param);
        var url = "api/LawyerChoices/Get_Profile_Details";

        this.generalService.PostData(url, UploadFile).then(data => {

            debugger
            this.dataResult = data;
            this.Customer_Name = this.dataResult[0].CustomerName;
            this.Mobile_No = this.dataResult[0].Mobile;
            this.Email_ID = this.dataResult[0].EmailID;
            this.package_Price = this.dataResult[0].packagePrice;
            this.Package_Discription = this.dataResult[0].PackageDiscription;
            this.Remaining_Days = this.dataResult[0].RemainingDays;
            this.Expiry_Date = this.dataResult[0].ExpiryDate;


        },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong,please try agian later', 'error');
            });

    }





}
