<div class="footer">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-lg-4">
                <div class="footer-about ">
                    <h2 class="fontt">About Us</h2>
                    <p>
                        Our commitment to excellence and customer satisfaction is evident in everything we do, from the quality of our content to the user experience we provide.
                    </p>
                </div>
            </div>
            <div class="col-md-6 col-lg-8">
                <div class="row">
                    <!--<div class="col-md-6 col-lg-4">
        <div class="footer-link">
            <h2> Our Services </h2>
            <a href="">Civil Law</a>
            <a href="">Family Law</a>
            <a href="">Business Law</a>
            <a href="">Education Law</a>
        </div>
    </div>-->
                    <div class="col-md-6 col-lg-4 ">
                        <div class="footer-link">
                            <h2 class="fontt">Useful Pages</h2>
                            <a href="/about-us">About Us</a>
                            <a href="/contact">Contact Us</a>
                            <a href="/Subscribe">Subscribe</a>
                            <a href="/login">Login</a>
                            <a routerLink="/Products">Registration</a>

                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="footer-link">
                            <h2 class="fontt"> Useful Links</h2>
                            <a href="privacypolicy.html"target="_blank">Privacy Policy</a>
                            <a href="termsandconditions.html"target="_blank">Terms and Conditions</a>
                            <a href="refundpolicy.html"target="_blank">Refund policy</a>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="footer-contact">
                            <h2 class="fontt">Get In Touch</h2>
                           
                            <address>
                                <i class="fa fa-map-marker-alt"></i> SIARA MARKETING PRIVATE LIMITED<br />
                                16-11-511/D/385, SHALIVAHANA NAGAR,<br /> Malakpet Colony Amberpet Hyderabad<br />
                                Telangana - 500036<br />
                                India
                            </address>
                            
                            <p><i class="fa fa-phone-alt"></i>+91 9343814598</p>
                            <p><i class="fa fa-envelope"></i>info@lawyerschoice.com</p>
                            <div class="footer-social">
                                <a href=""><i class="fab fa-twitter"></i></a>
                                <a href=""><i class="fab fa-facebook-f"></i></a>
                                <a href=""><i class="fab fa-youtube"></i></a>
                                <a href=""><i class="fab fa-instagram"></i></a>
                                <a href=""><i class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="container copyright">
        <div class="row">
            <div class="col-md-6">
                <p>&copy;Copyright 2023 - All Rights Reserved - <a href="#">SIARA MARKETING PRIVATE LIMITED</a></p>
            </div>
            <div class="col-md-6">
                <p>Powered By <a href="https://gagri.net/" target="_blank">Gagri Global IT Services</a></p>
            </div>
        </div>
    </div>
</div>
