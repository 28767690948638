import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../../Services/generalservice.service';
import { CartService } from '../../../Services/cart.service';

//import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';


import { Router } from '@angular/router';


@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    
    
    selectedItem: any = 'Home';
    ProductsSpecifications: any[];
    items: any[];
    FilterSpecData: any;
    filteredSpecifications: any[];
    SpecID: any;
    SpecName: any;
    Productsdata: any;
  ChemicalName: any;
  searchcomp: any;
  selectedcompanys: any;
   // LoginDetails: any;
  cartItemsCount: any;
  searchprods: any;
  selectedProduct: any;
    searchJudge: any;
    selectedJudgment: any;
    searchlist: any[];
    LoginDetails: any;
   // searchcomp: any;

    constructor(public generalService: GeneralService, public router: Router, public CartService: CartService) {
        debugger
       


        this.LoginDetails = JSON.parse(localStorage.getItem("LoginDetails"));
    }

    ngOnInit() {
        this.CartService.getProducts().subscribe(res => {/*getting cart product list count from cartservice*/
          this.cartItemsCount = res.length
     
        })

  }
 
  locationreload() {
    window.location.reload();
  }
  countReturn() {
        this.CartService.getProducts().subscribe(res => {/*getting cart product list count from cartservice*/

            this.cartItemsCount = res.length
            return this.cartItemsCount
        })
        return this.cartItemsCount
    }
    clearValue() {
        this.Productsdata = "";
    }
    GetSpecdata(filteredSpecifications) {
        debugger
        this.Productsdata;
        localStorage.removeItem('Cattegoryid');
        localStorage.removeItem('CatlogID');
        localStorage.setItem("CatlogID", filteredSpecifications.Catalog_No)
        var route = this.router.url
        this.Productsdata = '';
        this.ChemicalName = '';
        if (route == '/products-info') {
            localStorage.setItem("Cattegoryid", filteredSpecifications.CategoryID);
            window.location.reload();
        }
        else {
            localStorage.setItem("Cattegoryid", filteredSpecifications.CategoryID);
            this.router.navigateByUrl('/products-info');


        }

    }
    filterSpecifications(event) {
        debugger
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        let filtered: any[] = [];
        let query = event.query;

        var UploadFile = new FormData();
        UploadFile.append("name", query);
        var url = "/api/Product/GettingsearchProductCatlogandcasandchemicalname";
        debugger
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger
            this.filteredSpecifications = [];
            this.filteredSpecifications = data

        }, err => {
            // this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
        });


    }

    //filterTests() {
    //    debugger
    //    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    //    let filtered: any[] = [];
    //    var UploadFile = new FormData()
    //    UploadFile.append("Param", this.selectedcompanys)

    //    var url = "api/Product/Search_Products"
    //    this.generalService.PostData(url, UploadFile).then(data => {
    //        this.searchcompany = data
    //    }, err => {
    //        //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    //    });
    //}


  filterSearch(event) {
    debugger
      let filtered: any[] = [];
        var UploadFile = new FormData()
      UploadFile.append("Param1", this.selectedJudgment)
        var url = "api/LawyerChoices/SearchKeywords"
      this.generalService.PostData(url, UploadFile).then(data => {
        debugger
        this.searchJudge = data
      }, err => {
      });
   }

    clearItem(autocomplete: any) {
        this.searchlist = [];
        debugger
        autocomplete.value = '';
        this.filterSearch(event);
        window.location.reload();
        //this.searchlist = this.searchcompany;
    }
    filter(event) {
        debugger
        let query = event.detail.value;

        this.searchlist = [];

        this.searchlist = this.searchJudge.filter((KR) => {
            return (
                KR.ChemicalName.toLowerCase().indexOf(query.toLowerCase()) > -1);

        })

    }

  navigatingToSearchedProduct(data) {
    debugger
    console.log(data);
    var prodName = ""; var prodarr = []
    prodarr.push(data)
    var str = data.Catalog_No.split(" ")//spliting the category name into string array where space occured
    for (let i = 0; i < str.length; i++) {
      prodName += "_" + str[i];
    }
  //  this.generalService.setProducts(prodarr)
    this.router.navigate(['/Product/' + prodName]);
  }
    FilterList(id) {
        debugger

        localStorage.removeItem('catid');
        localStorage.setItem("catid", id);
        var route = this.router.url
        if (route == '/Products') {
            window.location.reload();
        }
        else {
            this.router.navigateByUrl('/Products');

        }

    }
    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }
    listClick(val) {
        this.selectedItem = val;
    }
  






    logout() {
        debugger
        event.preventDefault();
        localStorage.removeItem("LoginDetails");
        this.router.navigateByUrl('/login');
        this.LoginDetails = null;
    }

}
