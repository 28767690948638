import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ViewportScroller } from '@angular/common';
import { GeneralService } from '../../Services/generalservice.service';


import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';


import { Router } from '@angular/router';
@Component({
    selector: 'app-home-two',
    templateUrl: './home-two.component.html',
    styleUrls: ['./home-two.component.scss']
})
export class HomeTwoComponent implements OnInit {

    dataResult: any = [];
    arr: any;

    constructor(private viewportScroller: ViewportScroller, public router: Router, public generalService: GeneralService, public http: HttpClient) {}

    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
       // this.GetNews_Events();
    }
    //GetNews_Events() {
    //    debugger
    //    var url = "api/Nutrigene/Get_cust_news";
    //    this.generalService.GetData(url).then(data => {
    //        this.dataResult = data;




    //        //console.log(data);
    //    }, err => {
    //        // this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
    //    })
    //}
    homeSlides: OwlOptions = {
		loop: true,
		nav: true,
		dots: false,
		autoplayHoverPause: true,
		items: 1,
		autoHeight: true,
		smartSpeed: 750,
		autoplay: true,
		navText: [
			"<i class='fa fa-angle-left'></i>",
			"<i class='fa fa-angle-right'></i>"
		]
    }
   
}
