import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { EmailValidators } from 'ngx-validators';
import { GeneralService } from '../Services/generalservice.service';
import { CookieService } from 'ngx-cookie-service';
import { ElementRef, Renderer2 } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
//import { EncryptionService } from '../Services/';
declare var $

import Swal from 'sweetalert2';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    arr: any[];
    logindata: string;
    loginDet: any;
    mobile: any;
    password: any;

    passwordshow: boolean = false;
    MobileNo: any;
    OTP: any;
    Pwd: any;
    Confirmpassword: any;
    Remember: any;
    CheckBox: any;
    randomnumber: any;
    public form: FormGroup;
    Mobile: any;
    LoginDetails: any; token: any;
    HomeUrl: any;
    dataResult: any;

    togglePasswordVisibility() {
        this.passwordshow = !this.passwordshow;
    }

    constructor(private router: Router, fb: FormBuilder, public http: HttpClient,
        public generalService: GeneralService, private cookieService: CookieService,
        private elementRef: ElementRef, private renderer: Renderer2) {
        //   localStorage.removeItem("LoginDetails");
        debugger
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));

    }
    close() {
        debugger
        const modal = this.elementRef.nativeElement.querySelector('#myModal2');
        this.renderer.setStyle(modal, 'display', 'none');
      //  $('#myModal2').modal('hide');
    }
    hide() {
        const modal14 = this.elementRef.nativeElement.querySelector('#myModal4');
        this.renderer.setStyle(modal14, 'display', 'none');
      //  $('#myModal4').modal('hide');
    }


    ngOnInit(): void {

    }

    Login(mobile, password) {
        debugger
        this.arr = []
        this.arr.push({
            Mobile: mobile,
            Password: password,

        })
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        var url = "api/LawyerChoices/Login";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            if (data != null) {
                debugger
              
                if (data != "Invalid User") {

                    this.logindata = JSON.stringify(data[0]);
                    localStorage.setItem("LoginDetails", this.logindata);
                    //this.generalService.ShowAlert('WARNING', 'Please Enter Valid Credentials.', 'warning');
                    if (data[0].PackageID != 0) {
                        this.router.navigate(['/Judges']).then(() => {
                            window.location.reload(); // Perform a complete page reload
                        });
                    }
                    else {
                      
                        this.router.navigate(['/Subscribe']).then(() => {
                            window.location.reload(); // Perform a complete page reload
                        });

                    }

                }
               
                else {
                    this.generalService.ShowAlert('WARNING', 'Please Enter Valid Credentials.', 'warning');
                }

            }
           
        }, error => ({
            title: 'ERROR',
            text: 'Something went wrong. Please try again later.',
            type: 'error',
            confirmButtonText: 'Ok'

        })
        );

    }


    /* upadte barrer token */
    updatetoken() {
        debugger
        var UploadFile = new FormData();
        UploadFile.append('Param', this.loginDet.CustomerID);
        var url = "api/Token/Gettoken";
        this.generalService.PostData(url, UploadFile).then((data: any) => {

        }).catch(error => {
        console.error('Error updating PackageID:', error);
        });
    }


    /*forgot password*/
    ForgotPassword() {
        this.MobileNo = "";
        this.OTP = "";
        this.Pwd = "";
        this.Confirmpassword = "";
        debugger
        const modal = this.elementRef.nativeElement.querySelector('#myModal2');
        this.renderer.setStyle(modal, 'display', 'block');
       // $('#myModal2').modal('show');
    }
    /*end code*/

    /*Check mobile there or not*/
    CheckMobile(Mobile) {
        debugger;
        if (Mobile == undefined || Mobile == "" || Mobile == null) {
            debugger;
            alert("Please Enter Mobile Number")
            // this.openToast();
        }
        else {
            var UploadFile = new FormData();
            UploadFile.append("Mobile", Mobile);
            UploadFile.append("Flag", "1");
            var url = "api/LawyerChoices/LC_Check_Mobile_Email"
            debugger;
            this.generalService.PostData(url, UploadFile).then(data => {
                debugger;
                if (data == 'EXIST') {
                    this.OTP = ""
                    this.Mobile = Mobile;
                    this.SendOtpForMobile();
                    const modal12 = this.elementRef.nativeElement.querySelector('#myModal2');
                    this.renderer.setStyle(modal12, 'display', 'none');
                 //   $('#myModal2').modal('hide');
                    const modal = this.elementRef.nativeElement.querySelector('#myModal3');
                    this.renderer.setStyle(modal, 'display', 'block');
                  //  $('#myModal3').modal('show');
                }
                else {

                    alert("Please Enter Valid Mobile Number")
                }
            }, err => {
            });
        }

    }
/*end code*/

    /* send otp for registration */
    SendOtpForMobile() {
        debugger
        this.randomnumber = Math.floor(1000 + Math.random() * 9000);
        var msg = "Your Customer OTP is:" + this.randomnumber + " LC ";
        var UploadFile = new FormData();
        UploadFile.append("Mobile", this.Mobile);
        UploadFile.append("SMS", msg);
        UploadFile.append("OTP", this.randomnumber);
        var url = 'api/LawyerChoices/SendOtpToMobile';
        this.generalService.PostData(url, UploadFile).then(data => {
            if (data == "SUCCESS") {

            }
            else {

            }
        });
    }
    /*end code*/

    /*verifyotp for forgotpassword*/
    VerifyOtp(otp) {
        debugger
        if (this.randomnumber = otp) {
            const modal13 = this.elementRef.nativeElement.querySelector('#myModal3');
            this.renderer.setStyle(modal13, 'display', 'none');
           // $('#myModal3').modal('hide');
            const modal14 = this.elementRef.nativeElement.querySelector('#myModal4');
            this.renderer.setStyle(modal14, 'display', 'block');
         //   $('#myModal4').modal('show');
        }
        else {
            alert("Invalid OTP");
        }
    }
/*end region*/
    

    /*Change password*/
    ChangePassword(password, confirmpassword) {
        if (password == undefined || password == null || password == "" || confirmpassword == undefined || confirmpassword == null || confirmpassword == "") {
            alert("Please enter password");
        }
        else {
            if (password != confirmpassword) {
                alert("password and confirmpassword must be same");
            }
            else {
                var UploadFile = new FormData();
                UploadFile.append("Mobile", this.Mobile);
                UploadFile.append("Password", password);
                var url = "api/LawyerChoices/LC_Fotgot_Password"
                debugger;
                this.generalService.PostData(url, UploadFile).then(data => {
                    debugger;
                    if (data == 'SUCCESS') {
                        this.generalService.ShowAlert('SUCESS', 'Change Password Successfully.', 'success')
                        const modal14 = this.elementRef.nativeElement.querySelector('#myModal4');
                        this.renderer.setStyle(modal14, 'display', 'block');
                        //  $('#myModal4').modal('hide');
                    }
                }, err => {
                    const modal14 = this.elementRef.nativeElement.querySelector('#myModal4');
                    this.renderer.setStyle(modal14, 'display', 'block');
                   // $('#myModal4').modal('hide');
                    this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
                });
            }
        }
    }
/*endregion*/


    //Rememberb password
    RememberMe(ev) {
        debugger
        if (ev.target.checked == true) {
            this.Remember = true;
            this.cookieService.set('email', this.form.value.email);
            this.cookieService.set('password', this.form.value.password);
            this.cookieService.set('RememberMe', 'Checked');
            this.CheckBox = 'Checked'
        }
        else if (ev.target.checked == false) {
            // this.cookieService.set('RememberMe', 'UnChecked');
            this.CheckBox = ''
            this.Remember = false;
            this.cookieService.deleteAll();

        }
    }


}
