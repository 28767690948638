import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { EmailValidators } from 'ngx-validators';
import { GeneralService } from '../../Services/generalservice.service';
import { CookieService } from 'ngx-cookie-service';
import { ElementRef, Renderer2 } from '@angular/core';
import { EncryptionService } from '../../Services/encryption.service';
declare var $

import Swal from 'sweetalert2';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
   encapsulation: ViewEncapsulation.None

})
export class RegistrationComponent {

   public router: Router;
    public form: FormGroup;
    public name: AbstractControl;
    public mobile: AbstractControl;
    public email: AbstractControl;
    public password: AbstractControl;
    public confirmPassword: AbstractControl;
    arr: any[];
    logindata: any;
    OTP: any;
    Mobile: any;
    randomnumber: any;
    loginDet: any;
    passwordshow: boolean = false;
    otpSent: boolean = true;

    togglePasswordVisibility() {
        this.passwordshow = !this.passwordshow;
    }
    constructor(router: Router, fb: FormBuilder, public generalService: GeneralService,
        private elementRef: ElementRef, private renderer: Renderer2) {
        debugger
        this.router = router;
        this.form = fb.group({
            name: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
            mobile: ['',],
            email: ['', Validators.compose([Validators.required, emailValidator])],
            password: ['', Validators.required],
            Disclaimer: ['', Validators.required]
        });

        this.name = this.form.controls['name'];
        this.mobile = this.form.controls['mobile'];
        this.email = this.form.controls['email'];
        this.password = this.form.controls['password'];
        this.confirmPassword = this.form.controls['confirmPassword'];

        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));

    }


    public onSubmit() {
        debugger
        if (!this.form.value.name || !this.form.value.mobile || !this.form.value.email
            || !this.form.value.password || !this.form.value.Disclaimer) {
            this.generalService.ShowAlert('WARNING', 'Please enter All fields values.', 'warning');
            return; // Stop further execution
        }
        debugger
        this.arr = []
        this.arr.push({
            CustomerName: this.form.value.name,
            Mobile: this.form.value.mobile,
            EmailID: this.form.value.email,
            Password: this.form.value.password,
        })
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '1');
        var url = "api/LawyerChoices/LC_Add_Update_Registartion";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            if (data != "") {
                this.generalService.ShowAlert("SUCCESS", 'Your Registration Completed Successfully', 'Success');
                this.Login();
            }

        }, err => {
            this.generalService.ShowAlert('WARNING', 'Please Enter Valid Credentials.', 'warning');
        });
    }



    Login() {
        debugger
        this.arr = []
        this.arr.push({
            //UserID: this.loginDet.UserID,
            //TokenID: this.loginDet.TokenID,
            Mobile: this.form.value.mobile,
            Password: this.form.value.password,

        })
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        var url = "api/LawyerChoices/Login";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            if (data != null) {
                debugger
                if (data != "Invalid User") {

                    this.logindata = JSON.stringify(data[0]);
                    localStorage.setItem("LoginDetails", this.logindata);
                  //  this.CheckMobile(this.form.value.mobile)
                        this.router.navigate(['/Judges']).then(() => {
                            window.location.reload();
                        });
                               
                }
                else {
                    this.generalService.ShowAlert('WARNING', 'Please Enter Valid Credentials.', 'warning');
                }

            }
            else {
                this.generalService.ShowAlert("WARNING", "Please Enter Valid Credintials", "warning");
            }
        }, error => ({
            title: 'ERROR',
            text: 'Something went wrong. Please try again later.',
            type: 'error',
            confirmButtonText: 'Ok'

        })
        );

    }

    /*Check mobile there or not*/
    CheckMobile(mobile) {
        debugger;
        var Mobile = this.form.value.mobile
        if (Mobile == undefined || Mobile == "" || Mobile == null) {
            debugger;
          //  this.generalService.ShowAlert('Alert',"Please Enter Mobile Number",'Alert')
        }
        else {
            var UploadFile = new FormData();
            UploadFile.append("Mobile", Mobile);
            UploadFile.append("Flag", "1");
            var url = "api/LawyerChoices/LC_Check_Mobile_Email"
            
            this.generalService.PostData(url, UploadFile).then(data => {
                debugger;
                if (data == 'EXIST') {
                    this.generalService.ShowAlert('Warning', "Mobile number already exists. Please log in or use a different mobile number.",'Warning')
                }
                else {
                    this.SendOtpForMobile(this.form.value.mobile)
                    const modal = this.elementRef.nativeElement.querySelector('#myModal3');
                    this.renderer.setStyle(modal, 'display', 'block');
                }
            }, err => {
                this.generalService.ShowAlert("error", 'Please Enter valid Mobile Number', 'error');
            });
        }

    }
/*end code*/

    /* send otp for registration */
    SendOtpForMobile(value: Object) {
        debugger
        this.randomnumber = Math.floor(1000 + Math.random() * 9000);
        var msg = "Your Customer OTP is:" + this.randomnumber + " LC ";
        var UploadFile = new FormData();
        UploadFile.append("Mobile", this.mobile.value);
        UploadFile.append("SMS", msg);
        UploadFile.append("OTP", this.randomnumber);
        var url = 'api/LawyerChoices/SendOtpToMobile';
        this.generalService.PostData(url, UploadFile).then(data => {
            if (data == "SUCCESS") {
                //this.verifyotp1(this.randomnumber, value)
            }
            
        });
    }
    /*end code*/

    verifyotp1(otp, mobile) {
        debugger
        if (this.randomnumber = otp) {

            this.onSubmit();
        }
        else {
            alert("Invalid OTP");
        }
    }

    /*verifyotp for forgotpassword*/
    VerifyOtp(otp, value) {
        debugger
        if (this.randomnumber = otp) {
            $('#myModal3').modal('hide');

            debugger
            this.arr = []
            this.arr.push({
                //UserID: this.loginDet.UserID,
                //TokenID: this.loginDet.TokenID,
                CustomerName: this.name.value,
                Mobile: this.mobile.value,
                EmailID: this.email.value,
                Password: this.password.value,
                confirmPassword: this.confirmPassword.value

            })
            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '1');
            var url = "api/LawyerChoices/LC_Add_Update_Registartion";
            this.generalService.PostData(url, UploadFile).then(data => {
                debugger
                if (data = "Alredy mobile number is exist") {
                    this.generalService.ShowAlert("WARNING", "Mobile Number Exist", "warning");
                    this.Login()
                }
                else if (data != "") {
                  
                    this.Login()
                    this.router.navigate(['/Subscribe']).then(() => {
                        window.location.reload();
                    });
                }

            }, error => ({
                title: 'ERROR',
                text: 'Something went wrong. Please try again later.',
                type: 'error',
                confirmButtonText: 'Ok'

            })
            );
        }
        else {
            alert("Invalid OTP");
        }
    }
/*end region*/






}

export function emailValidator(control: FormControl): { [key: string]: any } {
    var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    if (control.value && !emailRegexp.test(control.value)) {
        return { invalidEmail: true };
    }
}

export function matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
        let password = group.controls[passwordKey];
        let passwordConfirmation = group.controls[passwordConfirmationKey];
        if (password.value !== passwordConfirmation.value) {
            return passwordConfirmation.setErrors({ mismatchedPasswords: true })
        }
    }
}


