import { Component, OnInit, Output, Input, EventEmitter, ChangeDetectorRef, NgZone, ViewChild } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { FormGroup, FormBuilder, FormsModule, Validators } from '@angular/forms'
import { MessageService } from 'primeng/api';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Directive, HostListener } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser';
import { Renderer2, ElementRef } from '@angular/core';
import { CartService } from '../../Services/cart.service';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
//import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap'; // Import NgbModal from ng-bootstrap (assuming you're using ng-bootstrap)

@Component({
    selector: 'app-subscribers',
    templateUrl: './subscribers.component.html',
    styleUrls: ['./subscribers.component.scss'],
    providers: [MessageService]

})
export class SubscribersComponent implements OnInit {
    @ViewChild('DeptModel') modalContent: any;
    cartData = [];
    ProductData = [];
    CatlogID: any;
    Specdata = []
    CatID: any;
    EnquiryAry: any = [];
    formGroup: any;
    admin: any;
    TemplateId: any;
    MessageText: any;
    //ApiURL: any;
    contactForm: FormsModule;
    spinner: boolean = false;
    imgBase64: any = '';

    HomeUrl: any;
    Catalog_No: any;
    whatsAppProductURL: string;
    dataResult1: any;
    Pdescription: any;
    Pack_Price: any;
    GSTprice: number;
    Pack_PricewithGst: any;
    Package_Name: any;
    LoginDetails: any;
    addressDetails: any;
    addressId: any;
    arr: any = [];
    n: any;
    subTotal: any;
    totalPrice: any;
    deliveryCharge: any;
    val: any;
    PaymentType: string;
    toast: any;
    cartService: any;
    Package_ID: any;
    PaymentID: any;
    TransactionID: any;
    TransactionStatus: any;
    loginDet: any; loginStatus: any;
    MSG: any;
    responsestatus: any; paymentRequest: any;
    redirectUri: any;
    TaxnId: any; Profiledetails: any; Remaining_Days: any;

    constructor(public generalService: GeneralService, public route: ActivatedRoute, private cdr: ChangeDetectorRef,
        public router: Router, public http: HttpClient, public https: HttpClient, private zone: NgZone,
        private domSanitizer: DomSanitizer, private renderer: Renderer2, //private modalService: NgbModal
    ) {
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;

            debugger
            this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        });
    }

    ngOnInit(): void {
        debugger
      
        this.ProductData = [];
        this.GetPacages();
      
    }
  

    GotoSubscriptionDetails(Package) {
        debugger
        localStorage.setItem("Package", JSON.stringify(Package));
        var param = this.loginDet.CustomerID;
        var UploadFile = new FormData();
        UploadFile.append("Param1", param);
        var url = "api/LawyerChoices/Get_Profile_Details";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.Profiledetails = data;
            this.Remaining_Days = this.Profiledetails[0].RemainingDays;

            if (this.Remaining_Days == 0 || this.Remaining_Days == null) {
                this.router.navigate(['/PackageDetails']);
            } else {
                this.generalService.ShowAlert("Warning", 'You Already Have an Active Subscription', 'Warning');
            }

        }).catch(error => {
            // Handle error if any
            console.error("Error occurred while fetching profile details:", error);
        });
    }

 
    openModal(item) {
        debugger
        if (this.loginDet != null) {
            debugger
            if (item.packagePrice == 0) {
                const currentDate = new Date();
                const createdDate = new Date(this.loginDet.CreatedDate);
                const timeDifference = currentDate.getTime() - createdDate.getTime();
                const daysDifference = timeDifference / (1000 * 3600 * 24); // Convert milliseconds to days             

                if (daysDifference <= 30) {
                    debugger
                    this.Package_ID = item.PackageID
                    this.Pdescription = item.PackageDiscription;
                    this.Pack_Price = item.packagePrice;
                    this.Package_Name = item.PackageName;
                    this.PlaceOrder(item.PackageID);
                    this.generalService.ShowAlert('Info', 'You have  30-day free trial', 'Info');
                    this.router.navigate(['/Judges']);
                }
                else {
                    this.generalService.ShowAlert('Info', 'You are 30-day free trial completed please subscribe', 'Info');
                }
            }
            else {
                //this.cdr.detectChanges();
                //this.zone.run(() => {
                $('#DeptModal').modal('show');
                //})
                //this.modalService.open(this.modalContent, { ariaLabelledBy: 'modal-primary-label', size: 'lg' });

                var Gst = 15;
                this.Package_ID = item.PackageID
                this.Pdescription = item.PackageDiscription;
                this.Pack_Price = item.packagePrice;
                this.Package_Name = item.PackageName;
                this.Pack_PricewithGst = ((item.packagePrice * Gst) / 100) + item.packagePrice;
                this.GSTprice = ((item.packagePrice * Gst) / 100)

                this.updatePackageID(item.PackageID);
            }
        }
        else {
            this.generalService.ShowAlert('Info', 'Please Login', 'Info');
            this.router.navigate(['/login']);

        }
    }

    openModalsss() {
        debugger
        // this.zone.run(() => {
        $('#myModal').modal('show'); // Show the modal
        // })

    }

    Showww(item) {
        $('#DeptModal').modal('show');
    }

    procedtoBuy1() {
        debugger
        if (this.LoginDetails) {
            $('#DeptModal').modal('hide');
            $('#myModal1').modal('show');
        }
        else {
            this.generalService.ShowAlert('Warning', 'Please add address for Delivery.', 'warning');
            // this.router.navigate(['/Address']);
        }

    }

    GetPacages() {
        debugger
        var url = "api/LawyerChoices/Get_PackageDetails"
        this.generalService.GetData(url).then(data => {
            debugger
            this.dataResult1 = data;

        },
            err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong,please try agian later', 'error');
            })
    }

    back() {
        this.router.navigateByUrl('/Products');
    }

    close() {
        $('#DeptModal').modal('hide');
        $('#myModal1').modal('hide');
    }

    sendWhatsapp(item) {
        var prodName = ""
        var str = item.ChemicalName.split(" ")//spliting the category name into string array where space occured

        prodName = str;

        this.whatsAppProductURL = "https://Altrakem.com/%23/Products/" + prodName
        window.open("https://api.whatsapp.com/send?&text=" + this.whatsAppProductURL)
    }

    PlaceOrder(Package_ID) {
        debugger

        this.arr = [];
        this.n = ""
        var d = new Date();
        this.n = d.getTime();
        //this.offerPrice = !this.offerPrice ? 0 : !this.offerPrice;

        this.arr.push({

            UserID: this.loginDet.CustomerID,
            TokenID: this.loginDet.TokenID,
            SubscribtionNo: Package_ID,
            //TransactionID: this.TransactionID,
            //TransactionStatus: this.TransactionStatus,
            TransactionID: this.PaymentType === 'OnlinePay' ? 0 : this.TransactionID,
            TransactionStatus: this.PaymentType === 'OnlinePay' ? 0 : this.TransactionStatus,

            PackageID: Package_ID

        });

        let formData: FormData = new FormData();
        formData.append('Param', JSON.stringify(this.arr));
        formData.append('Flag', '1');
        var url = "api/LawyerChoices/SP_Payment_CRUD"
        // this.http.post(url, formData).pipe(map(res => res)).subscribe((data: any) => {
        this.generalService.PostData(url, formData).then((data: any) => {
            debugger
            if (data != "") {

                //  this.Login(this.loginDet.Mobile, this.loginDet.Password)

                if (this.PaymentType === "HandCash") {

                    this.generalService.ShowAlert("Success", "Your Subscription added successfully", "Success");
                    this.router.navigate(['/Judges']);
                    this.cancel();
                }
                else if (this.PaymentType = "OnlinePay") {
                    //this.procedtoBuy(this.n)
                    this.cancel()
                    //  this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Your Subscription added successfully' });
                    this.generalService.ShowAlert("Success", "Your Subscription added successfully", "Success");

                    this.router.navigate['/Judges'];
                }

            }


        });

    }

    Login(mobile, password) {
        debugger
        this.arr = []
        this.arr.push({
            //UserID: this.loginDet.UserID,
            //TokenID: this.loginDet.TokenID,
            Mobile: mobile,
            Password: password,

        })
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        var url = "api/LawyerChoices/Login";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            if (data != null) {
                debugger
                if (data != "Invalid User") {

                    this.loginDet = JSON.stringify(data[0]);
                    localStorage.setItem("LoginDetails", this.loginDet);
                    //this.generalService.ShowAlert('WARNING', 'Please Enter Valid Credentials.', 'warning');
                    if (data[0].PackageID != 0) {
                        this.router.navigate(['/Judges']).then(() => {
                            window.location.reload(); // Perform a complete page reload
                        });
                    }
                    else {
                        this.router.navigate(['/Subscribe']).then(() => {
                            window.location.reload(); // Perform a complete page reload

                        });

                    }

                }
                else {
                    this.generalService.ShowAlert('WARNING', 'Please Enter Valid Credentials.', 'warning');
                }

            }
            else {
                this.generalService.ShowAlert("WARNING", "Please Enter Valid Credintials", "warning");
            }
        }, error => ({
            title: 'ERROR',
            text: 'Something went wrong. Please try again later.',
            type: 'error',
            confirmButtonText: 'Ok'

        })
        );

    }

    private updatePackageID(packageID: string): void {
        debugger
        const url = "api/LawyerChoices/LC_Add_Update_Registartion";
        const formData: FormData = new FormData();
        formData.append('UserID', this.loginDet.CustomerID);
        formData.append('PackageID', packageID);
        this.generalService.PostData(url, formData).then((data: any) => {
            // Handle the response if needed
        }).catch(error => {
            console.error('Error updating PackageID:', error);
        });
    }


    cancel() {
        $('#DeptModal').modal('hide');
        $('#myModal1').modal('hide');
    }
    selectMood() {

        $('#myModal1').modal('show');

    }

    //procedtoBuy( n) {
    //    debugger;

    //    var UploadFile = new FormData();
    //    this.subTotal = this.Pack_PricewithGst;
    //    UploadFile.append('Name', this.loginDet.CustomerName);
    //    UploadFile.append('Mobile', this.loginDet.Mobile);
    //    UploadFile.append('Email', this.loginDet.EmailID);
    //    UploadFile.append('Amount', this.subTotal);
    //    UploadFile.append('TransactionId', n);
    //  //  UploadFile.append('BookingDetails', JSON.stringify(CartProducts));

    //    console.log("localStorage LoginDetails:", localStorage.getItem("LoginDetails"));
    //    console.log("loginDet before assignment:", this.loginDet);

    //    // ...
    //    var url = "api/LawyerChoices/PhonepeMakePayment";
    //    this.generalService.PostData(url, UploadFile).then((data: any) => {
    //        debugger;
    //        window.location.href = data.toString();
    //        this.cancel()
    //        // alert ('hello')
    //        // this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Your order placed successfully' });

    //        this.cartService.removeAllcart()
    //        this.val = 1

    //        this.router.navigate['/Home'];
    //        debugger

    //    }, err => {
    //        // this.router.navigate['/Cart'];
    //    });

    //}

    /*rediret payment gateway*/


    //procedtoBuy(CartProducts, n) {
    //    debugger
    //    // alert('hello')
    //    var UploadFile = new FormData();
    //    this.subTotal = this.totalPrice + this.deliveryCharge;
    //    // this.subTotal = this.subTotal + this.deliveryCharge
    //    UploadFile.append('Name', this.loginDet.CustomerName);
    //    UploadFile.append('Mobile', this.loginDet.Mobile);
    //    UploadFile.append('Email', this.loginDet.EmailID);
    //    UploadFile.append('Amount', this.subTotal);
    //    UploadFile.append('Productinfo', "Product");
    //    UploadFile.append('TransactionId', n);
    //    UploadFile.append('BookingDetails', JSON.stringify(CartProducts));
    //    //alert('Start')
    //    var url = "/api/Products/PaymentGateWayWEB";
    //    this.generalService.PostData(url, UploadFile).then((data: any) => {
    //        debugger


    //        window.location.href = data.toString();

    //        this.cancel()
    //        // alert ('hello')
    //        // this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Your order placed successfully' });

    //        this.cartService.removeAllcart()
    //        this.val = 1

    //        this.router.navigate['/Home'];
    //        debugger

    //    }, err => {
    //       // this.router.navigate['/Cart'];
    //    });
    //    //var arr = [];
    //    //arr.push({
    //    //    TokenID: this.LoginDetails.Token,
    //    //    UserId: this.LoginDetails.Id
    //    //})
    //    //let formData: FormData = new FormData();
    //    //formData.append('Param', JSON.stringify(arr));
    //    //var url = "/api/Products/Get_All_Orders"
    //    //this.generalService.PostData(url, formData).then((data1: any) => {
    //    //    if (data1 != "") {
    //    //        debugger
    //    //        this.OrderDetails = data1;
    //    //        this.plus = false;
    //    //        if (this.OrderDetails[0].TransactionStatus == 'Success') {
    //    //            this.sendsmsToAdmin();
    //    //            this.sendsmsToAdmin1();
    //    //            this.sendsms1();
    //    //            this.sendsms2();
    //    //        }
    //    //    }
    //    //});
    //}


    /*end*/

    getPaymentType(pType) {
        this.PaymentType = pType
    }

    /* localhost */

    Paywithphonepe123(Package_ID) {
        debugger
        var d = new Date(),
            n = d.getTime();
        this.TaxnId = n;
        const apiUrl = `http://localhost:54429/api/LawyerChoices/PhonepeMakePayment12`;
        // const apiUrl = `https://velwisher.breakingindiaapp.com/webservices/api/Refit/PhonepeMakePayment`; // Replace with your actual API URL
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-VERIFY': 'de322f3070034cd760014c922bcba729d43e01ad9b412faef5f0c526c08d834f###1',
        }); // Replace with your headers
        const dynamicCallbackUrl = 'https://velwisher.breakingindiaapp.com/#/Admin/Paymentstatus';
        this.paymentRequest = {

            "merchantId": "PGTESTPAYUAT91",
            "merchantTransactionId": "MT7850590068188114",
            "merchantUserId": "MUID123",
            "amount": 10000,
            "redirectUrl": "https://webhook.site/redirect-url",
            "redirectMode": "POST",
            "callbackUrl": "https://webhook.site/callback-url",
            "mobileNumber": "9999999999",
            "paymentInstrument": {
                "type": "PAY_PAGE"
            }
        };
        debugger
        this.https.post(apiUrl, this.paymentRequest, { headers: httpHeaders }).subscribe(
            (response: any) => {
                debugger
                let redirectUrl = response.data.instrumentResponse.redirectInfo.url;
                ////  window.open(redirectUrl, '_blank');
                window.location.href = redirectUrl;

                //const link = document.createElement('a');
                //link.target = '_blank';
                //link.href = redirectUrl;
                //link.click();

                console.log('API response:', response);
                localStorage.setItem("TransId", this.TaxnId);
                // this.Gettransactionstatus(this.TaxnId, Package_ID);
            }, (error: any) => {
                console.error('API error:', error);
            }
        )
    }

    /* end */


    /* phonepe integration */

    Paywithphonepe(Package_ID) {
        debugger
        var d = new Date(),
            n = d.getTime();
        this.TaxnId = n;
        // const apiUrl = `http://localhost:54429/api/LawyerChoices/PhonepeMakePayment`;
        const apiUrl = `https://velwisher.breakingindiaapp.com/webservices/api/Refit/PhonepeMakePayment`; // Replace with your actual API URL
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-VERIFY': 'df7191d8bd4018e6b2d00fc4b1b5dbe912efaa24a97e951d47f49e81f4a13930###1',
        }); // Replace with your headers
        const dynamicCallbackUrl = 'https://velwisher.breakingindiaapp.com/#/Admin/Paymentstatus';
        // Construct the payment request
        this.paymentRequest = {
            merchantId: 'VELWISHERONLINE',
            merchantTransactionId: this.TaxnId,
            merchantUserId: 'VELWISHER123',
            amount: '100',
            redirectMode: 'POST',
            //redirectUrl: dynamicCallbackUrl,
            //callbackUrl: dynamicCallbackUrl,
            mobileNumber: this.loginDet.Mobile,
            paymentInstrument: {
                type: "PAY_PAGE"
            }
        };
        debugger
        this.https.post(apiUrl, this.paymentRequest, { headers: httpHeaders }).subscribe(
            //Success callback function

            (response: any) => {
                debugger
                // this.response=JSON.parse(response);
                let redirectUrl = response.data.instrumentResponse.redirectInfo.url;
                //   redirectUrl = redirectUrl.substring(0);
                //   redirectUrl = redirectUrl.substring(0, redirectUrl.length-1)
                window.open(redirectUrl, '_blank');
                //window.location.href = redirectUrl;

                //const link = document.createElement('a');
                //link.target = '_blank';
                //link.href = redirectUrl;
                //link.click();

                console.log('API response:', response);
                localStorage.setItem("TransId", this.TaxnId);
                // this.Gettransactionstatus(this.TaxnId, Package_ID);
            }, (error: any) => {
                console.error('API error:', error);
                //handle the error here
            }
        )
    }

    /* end */

    Gettransactionstatus(Tid, Package_ID) {
        debugger
        var Url = `https://velwisher.breakingindiaapp.com/webservices/api/Refit/CheckPaymentStatus?merchantTransactionId=${Tid}`;
        //var Url = `http://localhost:54753/api/BuddyOnWheel/CheckPaymentStatus?merchantTransactionId=${this.TaxnId}`;
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-VERIFY': 'df7191d8bd4018e6b2d00fc4b1b5dbe912efaa24a97e951d47f49e81f4a13930###1',
            'X-MERCHANT-ID': 'VELWISHERONLINE'
        });
        this.https.get(Url, { headers: httpHeaders }).subscribe(
            (response: any) => {
                //alert(response.data.state);
                console.log('Status response:', response.data);
                //localStorage.setItem("Status", response.data.state)
                //localStorage.setItem("TransId", response.data.merchantTransactionId);
                this.responsestatus = response.data.state
                //this.generalService.presentAlert("Checking Pay3", response.data)
                //this.generalService.presentAlert("Pay Checking4", this.responsestatus);
                if (this.responsestatus == "COMPLETED" || this.responsestatus == "COMPLETED ") {
                    // this.InsertOrderDetails();                  
                    this.PlaceOrder(Package_ID)
                }
            },
        )
    }

}
